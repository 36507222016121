button{
    background-color: #30a84871;
    border: none;
    text-decoration: none;
    font-weight:600;
    text-align: right;
    height: 24px;
    border-radius: 8px;
    padding: auto;
}

button:hover{
    background-color: rgb(47, 138, 64);
}