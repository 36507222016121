* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    background-color: white;
    text-align: center;
    font-family: 'Mukta', sans-serif;
  }
  
  .navbar {
    display: flex;
    padding: 2% 6%;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar_list {
    flex: 1;
    text-align: right;
    font-weight: 600;
  }

  .logo_image{
    width: 8%;
    margin-left: -5%;
    border-radius: 10px;
  }
  
  .navbar_list ul {
    list-style: none;
    display: inline-block;
    position: relative;
    text-decoration: none;
  }
  
  .navbar_list li {
    display: inline;
    margin-left: 20px;
    padding: 2px;
    /* text-decoration: none; */
    color: rgb(0, 0, 0) !important; /* Change the color to black with !important */
  }
  
  .navbar_list li a {
    text-decoration: none;
    /* color: inherit !important; */
  }
  
  .navbar_list li a:hover{
    color: rgb(214, 11, 11);
    text-decoration: underline;
  }
  .navbar_list li a:active {
    color: rgb(214, 11, 11);
    text-decoration: underline;
  }
  
  .hamburger-menu {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .navbar {
      display: none;
    }
    
    .navbar_list {
      display: none;
    }
  
    li {
      display: block;
    }
  
    .mobile_navbar {
      
    }
  
    .hamburger-menu {
      display: block;
      text-align: right;
    }
  
    .navbar_list_mobile {
      margin-left: 65%;
      width: 30%;
      display: block;
      position: static;
      padding: auto;
      text-align: right;
    }
  
    li a {
      display: block;
      padding-right: 10px;
    }
  
    li a:hover,
    li a:active {
      color: rgb(214, 11, 11);
      text-decoration: underline;
    }
  }
  