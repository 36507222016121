* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.article {
  margin: auto;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #eaf5e4;
  text-align: center;
  border-radius: 12px;
  width: 98%;
}

.a_description {
  background: #b5debf;
  box-shadow:  20px 20px 60px #8eaa96,
               -20px -20px 60px #c0e6ca;
  width: 95%;
  margin: auto;
  border-radius: 20px;
  
}



/* .author_identity{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.qn_pic {
    display: flex;
    align-items: center;
  }
  
  .qn_profile_pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
  } */

.a_title {
  padding-top: 10px;
  font-weight: 700;
  font-size: larger;
}

.blog_details_title {
  font-weight: 700;
  font-size: larger;
}


.blog {
  padding-left: 10px;
}

.showmorebutton {
  margin-bottom: 10px;
}

.author_identity {
  display: flex;
  align-items: center;
}

.qn_pic {
  margin-right: 10px;
}

.qn_profile_pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.author {
  font-weight: bold;
}

.a_date {
  margin-left: auto;
  margin-right: 20px;
  font-style: italic;
  font-weight: bold;
}



.blog_btn {
  height: 10px;
  width: 50px;
  text-align: center;
}

.blog_details {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.blog_details_content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: #f1fffffc;
  margin: 7% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 20px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  /* Hide vertical scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.blog_details_content::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.blog_detailsclose {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.comment_detailsclose {
  color: #00380d;
  margin-left: 90%;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.blog_detailsclose:hover,
.blog_detailsclose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.blog_details_img {
  max-width: 100%;
  max-height: 100%;
  width: 40%;
  object-fit: contain;
  /* aspect-ratio: <original-aspect-ratio>; */
  margin: auto;
  display: block;
}

.blog_details_content input {
  border-radius: 10px;
  padding: 3px;
  border: 1px solid #000;
  margin: 5px;
}

.blog_details_blog {
  padding-left: 10px;
  padding-top: 20px;
}
.blog_details_title {
  font-weight: 800;
  padding-bottom: 20px;
}

.blog_detailsauthor {
  font-weight: 700;
  padding: 10px;
}
.comment_details {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.comment_detail {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: #f1fffffc;
  margin: 7% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  max-height: 80%;
  overflow-y: auto;
}

.comment_input {
  background-color: #d9e0d7;
}

.comment_blogs{
  width: 40%;
}

.comment_wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

/* .commenter,
.comment_time {
  font-weight: 700;
  margin-right: 10px;
} */

.comment_time {
  font-size: small;
}

.comment_content {
  flex-basis: 100%;
  font-weight: 600;
}


.blog_details_title,
.likes,
.likebutton,
.comments,
.addComment,
.reportBlog,
.blog_detailsauthor {
  text-align: center;
  padding-left: 10px;
}
.comments,
.addComment,
.reportBlog {
  display: inline-block;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .article {
    padding-bottom: 20px;
    margin-left: 3%;
    margin-right: 1%;
    background-color: #eaf5e4;
    text-align: left;
    border-radius: 12px;
    height: fit-content;
    width: 94%;
  }
  .img {
    margin-left: 3%;
    margin-right: 3%;
    height: 30%;
    width: 92%;
    border-radius: 10px;
    float: none;
  }
}
