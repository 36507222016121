.leaflet-container {
    height: 350px;
    width: 100%;
    border-radius: 20px;
    position: relative;
    z-index: 1;
  }
  
  @media (min-width: 1000px) {
    .leaflet-container {
      height: 400px;
      border-radius: 20px;
    }
  }
  