.thearpists_card_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
}
.therapists_heading {
  font-size: 2rem;
  font-weight: 600;
}
.therapists_container {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding-top: 2rem;
  justify-content: flex-start;
  align-items: center;
  background-color: #95c8a170;
  min-height: 100vh;
  width: 100vw;
}
