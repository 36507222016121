.article_form_modal{
  width: 100%;
}

.new_article_form{
    background-color: #84BE94;
    width: 100%;
    margin: auto;
    padding: 5%;
    border-radius: 10px;
}

.newFormControl{
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: none;
    background-color: #DAE6CF;
}

.newFormdateControl{
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: none;
    background-color: #DAE6CF;
    padding-left: 25%;
    padding-right: 20%;
}

.newFormArticle{
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: none;
    background-color: #DAE6CF;
    overflow-y: auto;
    /* Hide vertical scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
  .newFormArticle::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

.fileInputContainer {
    position: relative;
    display: inline-block;
  }
  
  .fileInputLabel {
    display: inline-block;
    padding: 8px 16px;
    background-color: #eaf5e4;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .fileInput {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  

@media screen and (max-width: 768px) {
    .new_article_form{
        background-color: #84BE94;
        width: 96%;
        margin: auto;
        padding: 5%;
        border-radius: 10px;
    }
    
}