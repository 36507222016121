/* .job_details_wrapper{
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    background: #c0e5c9bd;
    box-shadow:  8px 8px 16px #76ac84,
                 -8px -8px 16px #9ce4ae;  
    margin: auto;
    border-radius: 20px;
    padding-bottom: 20px;
}

.close-button {
    background-color: rgb(72, 0, 255);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .close-button:hover {
    background-color: red;
  }

  .job_details_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    background: #c0e5c9bd;
    box-shadow: 8px 8px 16px #76ac84, -8px -8px 16px #9ce4ae;
    margin: auto;
    border-radius: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  
  .job_title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .job_company {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .job_description {
    text-align: left;
    margin: 20px;
  }
  
  .job_description div {
    margin-bottom: 5px;
  }
  
  .job_description .description {
    font-size: 16px;
  }
  
  .job_description .qualification,
  .job_description .responsibilities {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
  
  .job_description .qualification ul,
  .job_description .responsibilities ul {
    text-align: center;
    padding-inline-start: 0;
  }
  
  .job_description .qualification li,
  .job_description .responsibilities li {
    margin: 10px auto;
  }
  
  .job_description .job_url {
    margin-top: 10px;
  }
  
  .job_description .job_url a {
    display: inline-block;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .job_description .date {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
   */

   .job_details_wrapper {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    background: #c0e5c9bd;
    box-shadow: 8px 8px 16px #76ac84, -8px -8px 16px #9ce4ae;
    margin: auto;
    border-radius: 20px;
    padding-bottom: 20px;
  }
  
  .close-button {
    background-color: rgb(72, 0, 255);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .close-button:hover {
    background-color: red;
  }
  
  .job_title {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .job_company {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .job_description {
    text-align: left;
    margin: 20px;
  }
  
  .job_description div {
    margin-bottom: 5px;
    margin-left: 15px;
  }
  
  .job_description b {
    font-size: 16px;
  }
  
  .job_description ul {
    list-style: disc;
    padding-left: 35px;
    margin: 0;
  }
  
  .job_description .job_url {
    margin-top: 10px;
  }
  
  .job_description .job_url a {
    display: inline-block;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    cursor:pointer;
  }
  
  .job_description .date {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  