

.pending_Requests{
    background-color: #95c8a170;
    border-radius: 20px;
    width: 22%;
    position: fixed;
    padding: 10px;
    top: 15.4%;
    left: 10px;
    bottom: 0;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.pending_Requests::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}

.pending_Requests_Company{
    background-color: #95c8a170;
    border-radius: 20px;
    padding-bottom: 10px;
}

.approve_button{
    margin-right: 10px;
}