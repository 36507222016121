
.apartment{
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    /* padding-top: 20px; */
    background: #b5debf;
    box-shadow: 20px 20px 60px #8eaa96, -20px -20px 60px #c0e6ca;
    text-align: left;
    border-radius: 12px;
    text-align: center;
}

.apartment_img{
    padding-top: 6px;
    height: 170px;
    padding-right: 5.5px;
    width: 95%;
    border-radius: 10px; 
}

.apartment_title{
    padding-top: 5px;
    font-weight: 600;
}

.ap_date{
    font-family: 'Charmonman', cursive;
}

.apartment_details{
    background-color: #a7cfb3cf;
    border-radius:  10px;
}