.user__details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.user_profile_pic{
    width: 30%;
    height: 30%;
    border-radius: 50%;
    margin-top: 1.5rem;
}

.user__Name{
    font-size: 1rem;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}

.user__Bio{
    font-size: 0.8rem;
    font-size: 16px;
    font-weight: 600;
    color: #038427;
}

.user_details{
    background: #b5debf;
    box-shadow: 20px 20px 60px #8eaa96, -20px -20px 60px #c0e6ca;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    text-align: center;
    margin-top: 2rem;
    border-radius: 20px;
    overflow-y: auto; /* Enable vertical scrolling if content exceeds maximum height */
    overflow-y: auto;
    /* Hide vertical scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
.details::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
.details h5{
    padding-top: 10px;
    font-style: italic;
}

.edit__button{
    border: none;
    cursor: pointer;
    font-size: small;
    font-style: italic;
}


.change__button{
    border: none;
    cursor: pointer;
    font-size: small;
    font-style: italic;
    margin-left: 48%;
    margin-bottom: 10px;
}

.details_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0.5rem;
    font-weight: 500;
    font-style: italic;
    color: #858080;
}

.password_form_control{
    background-color: #b8dfc4;
    width: 90%;
    margin: auto;
    padding: 5%;
    border-radius: 10px;
}

.passwordFormControl{
    width: 80%;
    height: 50px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: none;
    background-color: #DAE6CF;
}