
.property-details-container {
    height: 100vh;
    background-color: rgb(181, 222, 191);
    color: #000000;
    overflow: scroll;
    padding-bottom: 10%;
  }
  
  .property-details-info {
    padding-top: 80px;
  }
  
  .SliderContainer {
    margin: 0 auto;
  }
  
  .slides .slick-prev,
  .slides .slick-next {
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0;
  }
  
  .slides {
    position: relative;
  }
  
  .slides .slick-prev,
  .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
  }
  
  .slides .slick-prev {
    left: 15%;
  }
  
  .slides .slick-next {
    right: 15%;
  }
  
  .property-img {
    width: 80vw;
    height: 40vh;
    object-fit: cover;
    border-radius: 20px;
    margin: 0 auto;
  }
  
  .property-details-title {
    padding-left: 5vw;
    margin-bottom: 0;
  }
  
  .property-details-section {
    display: flex;
    flex-direction: column;
  }
  
  .property-details-section-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 80vw;
    padding-bottom: 0;
  }
  .property-details-section-top p {
    margin-bottom: 0;
  }
  .property-details-info-title {
    font-weight: bold;
  }
  
  .property-details-tag {
    color: #2d17ef;
  }
  
  .property-details-section-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    margin: 0 auto;
  }
  
  .property-details-address {
    font-size: 12px;
  }
  
  .property-details-price {
    display: flex;
    align-items: center;
  }
  .property-details-price-top {
    font-size: 12px;
  }
  .property-details-price-bottom {
    font-size: 16px;
    padding-left: 5px;
    color: #f52335;
  }
  
  .property-details-hr {
    width: 80vw;
    border: none;
    border-top: 1px solid #4a4a4a;
  }
  /* property details tags */
  .property-details-tags {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    width: 80vw;
  }
  
  .property-detials-tags-main {
    display: flex;
    flex-direction: column;
  }
  
  .property-details-tag-title {
    margin-bottom: 5px;
  }
  
  .property-details-tags-section {
    display: flex;
    align-items: center;
  }
  .property-details-tags-section p {
    margin: 0;
    padding-right: 5px;
  }
  
  /* property-details-description */
  
  .property-details-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    width: 80vw;
  }
  
  .property-details-title {
    padding-left: 0;
    font-size: 14px;
    font-weight: bold;
  }
  
  .property-details-text {
    padding-left: 50px;
    text-align: left;
    font-size: 13px;
  }
  
  /* property details group */
  
  .property-details-map {
    width: 80vw;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .property-details-contact {
    margin: 0 auto;
    width: 300px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 20px;
    border: 1px solid rgba(176, 121, 32, 0.5);
    border-radius: 20px;
    margin-bottom: 50px;
  }
  
  .property-details-image-container {
    margin: 0 auto;
    width: 155px;
    height: 155px;
    border: 1px solid rgba(35, 245, 84, 0.5);
    border-radius: 100%;
    padding: 10px;
  }
  
  .property-details-image {
    width: 155px;
    height: 155px;
    border-radius: 100%;
    margin: 0 auto;
  }
  
  .property-details-contact-title {
    margin-bottom: 0;
  }
  
  .property-details-call button {
    padding: 8px 20px;
    border: none;
    background-color: rgb(21, 165, 213);
    border-radius: 10px;
    color: #fff;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  .property-details-call button:hover {
    color: #000;
    transform: translateY(-3px);
  }
  
  .property-details-mail {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 100px;
    background-color: rgb(21, 165, 213);
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
  }
  .property-details-mail button {
    padding: 8px 20px;
    background-color: #f5a623;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(245, 166, 35, 0.7);
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  .property-details-mail button:hover {
    color: #000;
    transform: translateY(-3px);
  }
  
  @media (min-width: 1000px) {
    .property-details-container-group {
      display: flex;
      flex-direction: row;
    }
  
    .property-details-info {
      width: 60vw;
      padding-left: 30px;
      padding-right: 30px;
    }
  
    .property-img {
      width: 50vw;
      height: 55vh;
      object-fit: cover;
      border-radius: 20px;
    }
  
    .property-details-section-top {
      width: 50vw;
    }
  
    .property-details-section-bottom {
      width: 50vw;
    }
  
    .property-details-hr {
      width: 50vw;
    }
    /* property details tags */
    .property-details-tags {
      width: 50vw;
    }
  
    .property-details-description {
      width: 50vw;
    }
    .property-details-group {
      padding-top: 50px;
    }
    .property-details-map {
      width: 400px;
    }
  }
  
  .details{
    overflow: scroll;
  }