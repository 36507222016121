.jobs_posted{
    background-color: #95c8a170;
    border-radius: 20px;
    margin-left: 2%;
    width: 40%;
    position: fixed;
    top: 15.4%;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.jobs_posted::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}

.add_job_form{
    background-color: #95c8a170;
    border-radius: 20px;
    margin-right: 2%;
    width: 50%;
    position: fixed;
    top: 15.4%;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    padding-bottom: 20px;
}

.add_job_form::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}